<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="物料类型">
					<c-select :options="DIC_MATERIAL_TYPE" name="product_type"></c-select>
				</m-search-item>
				<m-search-item label="物料名称">
					<c-input name="product_name"></c-input>
				</m-search-item>
				<m-search-item label="物料编码">
					<c-input name="product_code"></c-input>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_material_edit')" color="sys" @click="createProduct">新建物料</c-button>
			</m-operate>

			<c-table ref="table" height="grow">

				<c-table-column
					label="物料类型"
					width="120"
					name="product_type"
				></c-table-column>

				<c-table-column
					label="物料名称"
					width="300"
					name="product_name"
				></c-table-column>

				<c-table-column
					label="物料编码"
					width="120"
					name="product_code"
				></c-table-column>

				<c-table-column
					label="物料单位"
					width="50"
					name="unit"
				></c-table-column>

				<c-table-column
					label="物料/组件种类"
					width="100"
					name="material_kinds"
				>
					<template v-slot="{data}">
						<span v-if="data.material_kinds == 0" style="color:#999">0</span>
						<a v-else style="text-decoration: underline;color: #003dd9" @click="bomConfig(data)">
							<span>{{data.material_kinds}}</span>
						</a>
					</template>
				</c-table-column>

				<c-table-column
					label="创建人"
					width="120"
					name="create_user_realname"
				></c-table-column>
				
				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="190"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_material_edit')" @click="editProduct(data)">编辑</c-table-button>
						<c-table-button v-if="auth('b_material_edit')" @click="deleteProduct(data)">删除</c-table-button>
						<c-table-button v-if="auth('b_material_bom')" @click="bomConfig(data)">产品组件配置</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<!--新增、编辑 弹框-->
		<c-dialog ref="productEdit" :title="editState ? '编辑物料' : '新建物料'" width="500" @resolve="submitProduct">
			<c-form ref="productForm" unit-width="100%">
				<c-form-item label="物料类型" required>
					<c-select :options="DIC_MATERIAL_TYPE" name="product_type"></c-select>
				</c-form-item>
				<c-form-item label="物料名称" required>
					<c-input name="product_name" maxlength="200"></c-input>
				</c-form-item>
				<c-form-item label="物料编码">
					<c-input name="product_code" maxlength="50" :disabled="editState"></c-input>
					<template #tip>
						<span style="color:red">物料编码不填写则自动生成，生成后不支持更改</span>
					</template>
				</c-form-item>
				<c-form-item label="物料单位">
					<c-input name="unit" maxlength="5"></c-input>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>
		<!--笔记BOM信息弹窗-->
		<c-dialog ref="bomDialog" title="产品组件配置" width="900" height="600" @resolve="submitProductBom" :button="this.bom_edit_flag">
			<c-form ref="productBomForm" unit-width="100%">
				<c-form-item label="商品编码" width="400">
					<c-input name="product_code" disabled></c-input>
				</c-form-item>
				<c-form-item label="商品名称" width="400">
					<c-input name="product_name" disabled></c-input>
				</c-form-item>
				<c-form-item label="商品基数" width="300" required>
					<c-input name="product_count" type="number" decimal="5" range="0.00001,999999" :disabled="!this.bom_edit_flag"></c-input>
				</c-form-item>
				<c-form-item label="组件/物料">
					<div class="flex-start" style="padding-bottom: 1px">
						<c-button @click="selectMaterial">新增组件/物料</c-button>
					</div>
					<c-table ref="materialTable" :data="materialData" :paging="false" height="350" border>
						<c-table-column
							label="组件/物料代码"
							name="material_code"
							width="100"
						>
						</c-table-column>

						<c-table-column
							label="组件/物料名称"
							name="material_name"
							width="120"
						>
						</c-table-column>

						<c-table-column
							label="单位"
							name="material_unit"
							width="50"
						>
						</c-table-column>

						<c-table-column
							label="数量"
							name="material_count"
							width="80"
							required
						>
							<template #default="{data}">
								<c-input name="material_count" type="number" decimal="5" range="0.00001,999999"  width="80" v-model="data.material_count"></c-input>
							</template>
						</c-table-column>
						<c-table-column
							label="备注"
							name="remark"
							width="120"
						>
							<template #default="{data}">
								<c-input name="remark" maxlength="50" width="80" v-model="data.remark"></c-input>
							</template>
						</c-table-column>
						<c-table-column
							type="button"
							label="操作"
							width="50"
						>
							<template v-slot="{data}">
								<c-table-button @click="removeMaterial(data)">删除</c-table-button>
							</template>
						</c-table-column>
					</c-table>
				</c-form-item>
			</c-form>
		</c-dialog>
		<!--物料选择弹框-->
		<material-selector ref="materialSelector" multiple @resolve="handleMaterialSelect"></material-selector>
  </page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'
import materialSelector from "@/components/service/bom_material_selector.vue";
import {asyncMapState} from "@/store";

export default {
	name: 'm_material',
	components: {
		materialSelector

	},
	data() {
		return {
			  editState: false,
			  materialData: [],//组件物料数据
			  product_id:0,
			  bom_edit_flag:false //是否可以编辑BOM
		}
	},
	computed: {
		...mapState(['userInfo']),
		...asyncMapState(['DIC_MATERIAL_TYPE'])
	},
	mounted() {
		this.$refs.search.search();
	},
	methods: {
		//搜索
		searchHandle(data) {
			data.type = 2;
			this.$refs.table.load({
				url: '/product/list',
				data
			});
		},
		//新建物料
		createProduct() {
			this.editState = false;
			this.$refs.productForm.clear();
			this.$refs.productForm.set({"unit":"个"});
			this.$refs.productEdit.open();
		},
		//编辑物料
		editProduct(val) {
			this.editState = true;
			this.$refs.productForm.set(val);
			this.$refs.productEdit.open();
		},
		//删除物料
		deleteProduct(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除【'+val.product_name+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/product/delete',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '删除成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submitProduct(stop) {
			stop();
			this.$refs.productForm.submit({
				url: this.editState ? '/product/edit' : '/product/add',
				rule: {
					product_type: '请填选择物料类型',
					// product_code: '请输入物料编码',
					product_name: '请填写物料名称',
				},
				dataFormatter: data => {
					data.type = 2;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.productEdit.close();
				}
			});
		},
		//配置BOM数据
		bomConfig(data){
			if(this.auth('b_material_bom')){
				this.bom_edit_flag = true;
			}else {
				this.bom_edit_flag = false;
			}
			this.product_id = data.id;
			this.materialData = [];
			this.$refs.productBomForm.clear();
			this.request({
				url: '/bom/detail',
				data: {
					product_id: data.id
				},
				success: data => {
					if(data && data.product_info){
						this.$refs.productBomForm.set(data.product_info);
					}
					if(data && data.material_list){
						this.materialData = data.material_list
					}
					this.$refs.bomDialog.open();
				}
			});
		},
		//选择物料
		selectMaterial(){
			var material_ids = [];
			if (this.materialData && this.materialData.length > 0){
				this.materialData.forEach(item => {
					console.log(item);
					material_ids.push(item.material_id);
				});
			}
			this.$refs.materialSelector.open(this.product_id||0,material_ids);
		},
		//删除物料
		removeMaterial(data){
			// 删除指定元素
			let index = this.materialData.indexOf(data);
			if (index !== -1) {
				this.materialData.splice(index, 1);
			}
		},
		//确认选择物料
		handleMaterialSelect(rows){
			if (rows){
				rows.forEach(item => {
					var material = {};
					material.material_id = item.id;
					material.material_code = item.product_code;
					material.material_name = item.product_name;
					material.material_unit = item.unit;
					this.materialData.push(material);
				});
			}
		},
		//提交BOM数据
		submitProductBom(stop){
			stop();
			this.$refs.productBomForm.submit({
				url: '/bom/edit',
				rule: {
					product_count: '请填写商品基数',
					materialData: [{
						type: 'function',  // 自定义函数校验
						fun: (value, data) => {
							if(this.materialData.length > 0){
								var flag = true;
								this.materialData.forEach(item => {
									if(!item.material_count || parseFloat(item.material_count) <= 0){
										flag = false;
									}
								});
								return flag;
							}else{
								return false;
							}
						},
						message: '请正确填写组件/物料信息'
					}]
				},
				dataFormatter: data => {
					data.product_id = this.product_id;
					data.material_list = this.materialData;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();//刷新列表
					this.$refs.bomDialog.close();//关闭弹窗
				}
			});
		}
  }
}
</script>